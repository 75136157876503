// const host = "http://localhost:8088";
const host = "https://hivee-club-541f376f6ed6.herokuapp.com";

const apiConfig = {
	key: "mailer2023Mangusta",
	Main: `${host}`,
	AdminCategories: `${host}/admin/categories/`,
	AdminTags: `${host}/admin/tags/`,
	AdminProviders: `${host}/admin/providers/`,
	AdminURL: `${host}/admin/url/`,
	Home: `${host}/home/`,
	Files: `${host}/files/`,
	Email: `${host}/emails/`,
	Provider: `${host}/providers/`,
};

export default apiConfig;
