import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import moment from "moment";

const OneLineSlider = (props) => {
	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: false,
	};

	const [] = useState();

	let slider = useRef(null);

	const slideBack = () => {
		slider.slickPrev();
	};

	const slideForward = () => {
		slider.slickNext();
	};

	return (
		<>
			{props.emails ? (
				<div className="home-listing-item-wrapper">
					<div className="home-listing-carousel-header-wrapper">
						<h2 className="home-listing-carousel-header">{props.title}</h2>
						<div className="home-listing-carousel-header-actions">
							<IconButton
								className="email-carousel-arrow"
								aria-label="previous"
								onClick={slideBack}
								name="next"
							>
								<ArrowBackIosIcon />
							</IconButton>
							<IconButton
								className="email-carousel-arrow"
								aria-label="previous"
								onClick={slideForward}
								name="back"
							>
								<ArrowForwardIosIcon />
							</IconButton>
						</div>
					</div>
					{props.emails ? (
						<Slider ref={(c) => (slider = c)} {...settings}>
							{props.emails.map((mail) => {
								return (
									<div className="home-emails-carousel-item-wrapper">
										<div className="home-emails-carousel-item-header">
											{/* <a href="#"> */}
											<Link
												to={`/provider/${mail.providerData._id}`}
												path="relative"
											>
												<span className="home-emails-carousel-item-provider">
													{mail.providerData.name}
												</span>
											</Link>
											{/* </a> */}
											<span className="home-emails-carousel-item-date">
												{moment(mail.date).format("MMM Do YY")}
											</span>
										</div>
										<div className="home-emails-carousel-item-thmbn">
											<Link to={`/single/${mail._id}`} path="relative">
												<img src={mail.preview} />
											</Link>
										</div>
										<div className="home-emails-carousel-item-subject">
											<Link to={`/single/${mail._id}`} path="relative">
												{mail.subject}
											</Link>
										</div>
									</div>
								);
							})}
						</Slider>
					) : (
						""
					)}
				</div>
			) : (
				""
			)}
		</>
	);
};

export default OneLineSlider;
