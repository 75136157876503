import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import logo from "../../Logo-hivee.png";

import { Link } from "react-router-dom";

import FormControl from "@mui/material/FormControl";

const AdminHeader = (props) => {
	const [] = useState();

	return (
		<>
			<AppBar position="static" className="navigation-bar-wrapper">
				<div className="navigation-left-wrapper">
					<div className="logo-wrapper">
						<Link to="/" relative="path">
							<img src={logo} alt="Logo" />
						</Link>
					</div>
					<ul className="navigation-menu-wrapper">
						<li className="navigation-menu-item">
							{/* <a href="/providers">Providers</a> */}
							<Link to="/admin/providers" relative="path">
								Providers
							</Link>
						</li>
						<li className="navigation-menu-item">
							{/* <a href="/categories">Categories</a> */}
							<Link to="/admin/categories" relative="path">
								Categories
							</Link>
						</li>
						<li className="navigation-menu-item">
							{/* <a href="/categories">Categories</a> */}
							<Link to="/admin/tags" relative="path">
								Tags
							</Link>
						</li>
						<li className="navigation-menu-item">
							{/* <a href="/categories">Categories</a> */}
							<Link to="/admin/urls" relative="path">
								URL Shortener
							</Link>
						</li>
					</ul>
				</div>
			</AppBar>
		</>
	);
};

export default AdminHeader;
