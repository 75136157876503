import React, { useState, useEffect } from "react";
import { useParams, redirect, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import UploadIcon from "@mui/icons-material/Upload";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";
import AWS, { S3 } from "aws-sdk";
import apiVariables from "../../api/apiVariables";
import { updateFileS3 } from "../../modules/s3repository";
import { processFile, deleteFile } from "../../modules/fileRepository";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const AdminProviderCreateEdit = (props) => {
	let { providerId } = useParams();
	let fileInput = null;
	const navigate = useNavigate();

	const [loading, setLoading] = useRecoilState(loadingAtom);

	const host = "AdminProviders";

	const endpointPurposes = {
		getData: "getData",
		getOne: "getOne",
		create: "create",
		update: "update",
		delete: "delete",
		updateCategories: "updateCategories",
	};

	const [categories, setCategories] = useState(null);
	const [tags, setTags] = useState();
	const [countries, setCountries] = useState();

	const [imgPreview, setImgPreview] = useState();

	const [newProvider, setNewProvider] = useState({
		tags: [],
	});

	const [nameError, setNameError] = useState(false);
	const [websiteError, setWebsiteError] = useState(false);
	const [countryError, setCountryError] = useState(false);
	const [logoError, setLogoError] = useState(false);
	const [categoryError, setCategoryError] = useState(false);
	const [tagsError, setTagsError] = useState(false);
	const [emailsError, setEmailsError] = useState(false);

	const [editMode, setEditMode] = useState(false);
	const [logoToDelete, setLogoToDelete] = useState();

	const [logoImgS3, setLogoImgS3] = useState();

	useEffect(() => {
		getdataFromApi();
	}, []);

	const getdataFromApi = () => {
		let request = {
			purpose: endpointPurposes.getData,
			provider: providerId,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);
				setCategories(result.categories);
				setTags(result.tags);
				setCountries(result.countries);
				if (providerId) {
					setEditMode(true);

					let categoryName = result.categories.filter((ct) => {
						return ct._id == result.provider.category;
					})[0].name;

					let tagNames = [];

					result.provider.tags.map((tag) => {
						let tagName = result.tags.filter((t) => {
							return t._id == tag;
						})[0].name;

						tagNames.push(tagName);
					});

					if (result.provider.logo) {
						setImgPreview(result.provider.logo);
					}

					let providerViewModel = {
						...newProvider, // Copy the existing state
						name: result.provider.name, // Update only the "name" field
						description: result.provider.description,
						website: result.provider.website,
						country: result.provider.locationCountry,
						emails: result.provider.emails,
						affiliateLink: result.provider.affiliateLink,
						affiliateDescription: result.provider.affiliateDescription,
						category: categoryName,
						tags: tagNames,
						logo: result.provider.logo,
						shipping: result.provider.shipping,
						_id: result.provider._id,
					};

					console.log(providerViewModel);

					setLogoToDelete(result.provider.logo);
					setNewProvider(providerViewModel);
					setEditMode(true);
				}
			}
		});
	};

	const handleInputChange = (event) => {
		if (event.target) {
			if (event.target.checked) {
				setNewProvider({
					...newProvider,
					[event.target.name]: event.target.checked,
				});
			} else {
				setNewProvider({
					...newProvider,
					[event.target.name]: event.target.value,
				});
			}
		}
	};

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const objectUrl = URL.createObjectURL(userFile);
		setImgPreview(objectUrl);
		setNewProvider({
			...newProvider,
			logo: userFile,
		});
	};

	const handleImgClear = () => {
		setImgPreview(null);
	};

	const handleProviderSave = () => {
		console.log("save provider: ", newProvider);

		let valid = validateForm();

		if (valid) {
			setLoading(true);

			if (newProvider.logo) {
				const name = newProvider.name;
				let fileObject = {
					name: name,
					img: newProvider.logo,
				};
				processFile("saveProvider", fileObject, saveProviderApiCall);
			} else {
				saveProviderApiCall();
			}
		}
	};

	const saveProviderApiCall = (logoImgUri) => {
		console.log(logoImgUri);
		let category = categories.filter((c) => {
			return c.name == newProvider.category;
		})[0]._id;

		let tagsSelected = [];

		newProvider.tags.map((pt) => {
			let selectedTag;

			tags.map((ta) => {
				console.log(ta.name, pt);
				if (ta.name == pt) {
					selectedTag = ta._id;
				}
			});

			tagsSelected.push(selectedTag);
		});

		let request = {
			purpose: endpointPurposes.create,
			provider: {
				name: newProvider.name,
				description: newProvider.description,
				website: newProvider.website,
				locationCountry: newProvider.country,
				shipping: newProvider.shipping,
				affiliateLink: newProvider.affiliateLink,
				affiliateDescription: newProvider.affiliateDescription,
				category: category,
				tags: tagsSelected,
				emails: newProvider.emails,
				logo: logoImgUri ? logoImgUri : null,
			},
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);

				navigate(`/admin/providers`, { replace: true });
			}
			setLoading(false);
		});
	};

	const handleCreateCancel = () => {
		console.log("cancel");
		navigate(`/admin/providers`, { replace: true });
	};

	const validateForm = () => {
		let valid = true;

		if (!newProvider.name) {
			valid = false;
			setNameError(true);
		} else {
			setNameError(false);
		}

		// if (!imgPreview) {
		// 	valid = false;
		// 	setLogoError(true);
		// } else {
		// 	setLogoError(false);
		// }

		if (!newProvider.website) {
			valid = false;
			setWebsiteError(true);
		} else {
			setWebsiteError(false);
		}

		if (!newProvider.country) {
			valid = false;
			setCountryError(true);
		} else {
			setCountryError(false);
		}

		if (!newProvider.category) {
			valid = false;
			setCategoryError(true);
		} else {
			setCategoryError(false);
		}

		if (!newProvider.tags) {
			valid = false;
			setTagsError(true);
		} else {
			setTagsError(false);
		}

		if (!newProvider.emails) {
			valid = false;
			setEmailsError(true);
		} else {
			setEmailsError(false);
		}

		return valid;
	};

	const handleUpdateSave = () => {
		setLoading(true);
		console.log("update save: ", newProvider);

		console.log(typeof newProvider.logo);

		if (newProvider.logo) {
			if (typeof newProvider.logo !== "string") {
				let imgUri = "";
				if (logoToDelete) {
					imgUri = logoToDelete;
				}

				console.log("delete old img: ", imgUri);
				deleteFile("deleteProvider", imgUri, updateSaveDeleteCallback);
				// updateFileS3(
				// 	"/providers",
				// 	newProvider.logo,
				// 	imgUri,
				// 	newName,
				// 	updateSaveApiCall
				// );
			} else {
				updateSaveApiCall();
			}
		} else {
			updateSaveApiCall();
		}
	};

	const updateSaveDeleteCallback = () => {
		let file = newProvider.logo;
		let newName = newProvider.name;

		let fileObject = {
			name: newName,
			img: file,
		};

		processFile("saveProvider", fileObject, updateSaveApiCall);
	};

	const updateSaveApiCall = (newLogo) => {
		console.log("api call new logo: ", newLogo);

		let categoryString = categories.filter((cat) => {
			return cat.name == newProvider.category;
		})[0]._id;

		let tagsArray = [];

		newProvider.tags.map((pt) => {
			let tagName = tags.filter((t) => {
				return t.name == pt;
			})[0]._id;

			tagsArray.push(tagName);
		});

		let providerObject = {
			...newProvider,
			logo: newLogo ? newLogo : newProvider.logo,
			category: categoryString,
			tags: tagsArray,
			id: newProvider._id,
		};

		let request = {
			purpose: endpointPurposes.update,
			provider: providerObject,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			setLoading(false);
			if (result.success) {
				navigate(`/admin/providers`, { replace: true });
			}
		});
	};

	return (
		<>
			<div className="admin-home-wrapper">
				<h1 className="admin-home-page-header">
					{`${providerId ? "Edit" : "Create"} provider`}
					<div className="provider-create-actions-wrapper">
						<Button
							variant="contained"
							onClick={editMode ? handleUpdateSave : handleProviderSave}
						>
							Save
						</Button>
						<Button
							variant="outlined"
							onClick={handleCreateCancel}
							className="home-admin-body-change-handle-buttons-btn"
						>
							Cancel
						</Button>
					</div>
				</h1>
				<div className="home-admin-body-wrapper">
					<div className="home-admin-body-form home-admin-providers-form-wrapper">
						<Grid container spacing={2}>
							<Grid xs={6}>
								<div className="admin-form-row">
									<TextField
										id="name"
										className="admin-form-single"
										label="Name"
										name="name"
										variant="standard"
										value={newProvider.name || ""}
										onChange={handleInputChange}
										error={nameError}
										helperText={nameError ? "Name must be filled" : ""}
										required
									/>
								</div>
								<div className="admin-form-row">
									<TextField
										id="description"
										className="admin-form-multiline"
										label="Description"
										name="description"
										variant="standard"
										minRows="4"
										multiline
										value={newProvider.description || ""}
										onChange={handleInputChange}
									/>
								</div>
								<div className="admin-form-row">
									<TextField
										id="website"
										className="admin-form-single"
										label="Official website"
										name="website"
										variant="standard"
										required
										onChange={handleInputChange}
										value={newProvider.website || ""}
										error={websiteError}
										helperText={websiteError ? "Website must be filled" : ""}
									/>
								</div>
								{countries ? (
									<div className="admin-form-row">
										<FormControl
											className="countries-select-wrapper"
											variant="standard"
										>
											<InputLabel id="countries-label">Country</InputLabel>
											<Select
												id="country"
												className="admin-form-single"
												label="Country"
												name="country"
												variant="standard"
												onChange={handleInputChange}
												error={countryError}
												required
												value={newProvider.country || ""}
												helperText={
													countryError ? "Country must be choosen" : ""
												}
											>
												{countries.map((name) => (
													<MenuItem key={name} value={name}>
														{name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</div>
								) : (
									""
								)}
								<div className="admin-form-row">
									<h4>Shipping</h4>
									<Stack direction="row" spacing={1} alignItems="center">
										<Typography>Local only</Typography>
										<Switch
											name="shipping"
											checked={newProvider.shipping}
											onChange={handleInputChange}
										/>
										<Typography>Europe</Typography>
									</Stack>
								</div>
								<div className="admin-form-row">
									<h4>Logo*</h4>
									<Stack spacing={1} direction="row">
										<Button
											variant="outlined"
											startIcon={<UploadIcon />}
											onClick={fileClickHandler}
										>
											Upload image
										</Button>
										{imgPreview ? (
											<Button
												variant="text"
												onClick={handleImgClear}
												className="home-admin-body-change-handle-buttons-btn"
											>
												Clear image
											</Button>
										) : (
											""
										)}
									</Stack>
									{logoError ? (
										<div className="admin-categories-new-img-error-wrapper">
											<span>Provider should have logo</span>
										</div>
									) : (
										""
									)}
									<input
										type="file"
										style={{ display: "none" }}
										ref={(input) => {
											fileInput = input;
										}}
										accept="image/*"
										onChange={fileUploadHandler}
									/>
									<div className="admin-categories-new-img-preview-wrapper">
										{imgPreview ? (
											<img
												src={imgPreview}
												className="admin-categories-new-img-preview-img"
											/>
										) : (
											""
										)}
									</div>
								</div>
							</Grid>
							<Grid xs={6}>
								<div className="admin-form-row">
									{categories ? (
										<FormControl
											className="category-select-wrapper"
											variant="standard"
										>
											<InputLabel id="category-label">Category</InputLabel>
											<Select
												id="category"
												className="admin-form-single"
												label="Category"
												name="category"
												variant="standard"
												onChange={handleInputChange}
												value={newProvider.category || ""}
												error={categoryError}
												required
												helperText={
													categoryError ? "Category must be choosen" : ""
												}
											>
												{categories.map((cat) => (
													<MenuItem key={cat._id} value={cat.name}>
														{cat.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										""
									)}
								</div>
								<div className="admin-form-row">
									{tags ? (
										<FormControl
											className="provider-tags-input-wrapper"
											variant="standard"
										>
											<InputLabel id="tags-label">Linked tags</InputLabel>
											<Select
												labelId="tags-label"
												id="tags"
												multiple
												required
												value={newProvider.tags}
												name="tags"
												onChange={handleInputChange}
												// onBlur={handleCategoriesSelectBlur}
												error={tagsError}
												helperText={tagsError ? "Choose at least one tag" : ""}
												input={
													<OutlinedInput
														id="select-multiple-categories"
														label="Linked categories"
													/>
												}
												renderValue={(selected) => (
													<Box
														sx={{
															display: "flex",
															flexWrap: "wrap",
															gap: 0.5,
														}}
													>
														{selected.map((value) => (
															<Chip
																key={
																	tags.filter((tag) => {
																		return tag.name == value;
																	})[0].name
																}
																label={
																	tags.filter((tag) => {
																		return tag.name == value;
																	})[0].name
																}
																// key={value}
																// value={value}
															/>
														))}
													</Box>
												)}
												MenuProps={MenuProps}
											>
												{tags.map((tag) => (
													<MenuItem key={tag._id} value={tag.name}>
														{tag.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									) : (
										""
									)}
								</div>
								<div className="admin-form-row">
									<TextField
										id="emails"
										className="admin-form-single"
										label="Sender emails"
										name="emails"
										variant="standard"
										onChange={handleInputChange}
										value={newProvider.emails || ""}
										error={emailsError}
										helperText={emailsError ? "At least one email needed" : ""}
									/>
								</div>
								<div className="admin-form-row">
									<TextField
										id="affiliate-link"
										className="admin-form-single"
										label="Affiliate link"
										name="affiliateLink"
										variant="standard"
										value={newProvider.affiliateLink || ""}
										onChange={handleInputChange}
									/>
								</div>
								<div className="admin-form-row">
									<TextField
										id="affiliate-description"
										className="admin-form-multiline"
										label="Affiliate description"
										name="affiliateDescription"
										variant="standard"
										minRows="4"
										multiline
										value={newProvider.affiliateDescription || ""}
										onChange={handleInputChange}
									/>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminProviderCreateEdit;
