import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "./AdminHeader";

const AdminLayout = (props) => {
	const [] = useState();

	return (
		<>
			<AdminHeader />
			<Outlet />
		</>
	);
};

export default AdminLayout;
