import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";

const HomeSubscribe = (props) => {
	const [] = useState();

	return (
		<>
			<div className="home-subscribe-wrapper">
				<Grid container spacing={0}>
					<Grid xs={10} xsOffset={1}>
						<div className="home-subscribe-inner-wrapper">
							<div className="home-subscribe-inner-text">
								<h3 className="home-subscribe-inner-text-header">
									Subscribe Hivee club
								</h3>
								<h4 className="home-subscribe-inner-text-subheader">
									Get E-mail updates about our latest shops and special offers
								</h4>
							</div>
							<div className="home-subscribe-inner-form">
								<input
									class="home-subscribe-inner-form-input"
									placeholder="Your email Address"
								/>
								<button class="home-subscribe-inner-form-btn">Sign Up</button>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default HomeSubscribe;
