import React, { useState, useEffect } from "react";
import HomeBanner from "../main/HomeBanner";
import MainLastEmails from "../main/MainLastEmails";
import HomeCategories from "../main/HomeCategories";
import HomeSubscribe from "../main/HomeSubscribe";
import HomeEmailsListing from "../main/HomeEmailsListing";
import HomeCompaniesListing from "../main/HomeCompaniesListing";
import Footer from "../shared/Footer";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const Home = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const host = "Home";

	const endpointPurposes = {
		getData: "getData",
	};

	const [providers, setProviders] = useState();
	const [tags, setTags] = useState();
	const [categories, setCategories] = useState();
	const [emails, setEmails] = useState();

	const [emailsLocal, setEmailsLocal] = useState();
	const [emailsCat1, setEmailsCat1] = useState();
	const [emailsCat2, setEmailsCat2] = useState();
	const [providersCat1, setProvidersCat1] = useState();

	useEffect(() => {
		setLoading(true);
		getdataFromApi();
	}, []);

	const getdataFromApi = () => {
		setLoading(true);
		let request = {
			purpose: endpointPurposes.getData,
			country: "de",
			emailsCategories: [
				"652edb9365fa1d141ff30b31",
				"652edba265fa1d141ff30b36",
			],
			providersCategories: ["652edb9365fa1d141ff30b31"],
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			console.log(result);
			if (result && result.success) {
				let emails = {};
				emails.promotionEmails = result.promotionEmails;
				setEmails(emails);
				setCategories(result.categories);
				setProviders(result.providers);
				setEmailsLocal(result.localEmails);
				setEmailsCat1(result.categoryEmails1);
				setEmailsCat2(result.categoryEmails2);
				setProvidersCat1(result.categoryCompanies.slice(0, 18));
				setTags(result.tags);
				setLoading(false);
			} else {
				console.log("request error: ", result ? result.message : "");
				setLoading(false);
			}
		});
	};

	return (
		<>
			<div className="home-body-wrapper">
				<HomeBanner categories={categories} />
				<HomeCategories
					tags={tags}
					promotionEmails={emails ? emails.promotionEmails : null}
				/>
				<HomeSubscribe />
				<HomeEmailsListing filter="national" emails={emailsLocal} />
				<HomeCompaniesListing filter="fashion" companies={providersCat1} />
				<HomeEmailsListing filter="fashion" emails={emailsCat1} />
				<HomeEmailsListing filter="beauty" emails={emailsCat2} />
				<Footer />
			</div>
		</>
	);
};

export default Home;
