import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";
import { deleteItemFromS3 } from "../../modules/s3repository";

const AdminProviders = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const host = "AdminProviders";

	const endpointPurposes = {
		get: "get",
		delete: "delete",
	};

	const [providers, setProviders] = useState();
	const [tags, setTags] = useState();
	const [categories, setCategories] = useState();
	const [data, setData] = useState();

	const [providersToDelete, setProvidersToDelete] = useState([]);
	const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

	const columns = [
		{
			name: "name",
			label: "Name",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "country",
			label: "Country",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "category",
			label: "Category",
			options: {
				filter: true,
				sort: true,
			},
		},
		{
			name: "affiliateLink",
			label: "Affiliate Link",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "actions",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				export: false,
				customBodyRender: (value) => (
					<div className="table-row-action-people-buttons-wrapper">
						<Tooltip title="Edit">
							<Link to={"/admin/providers/editcreate/" + value}>
								<IconButton aria-label="Edit">
									<EditIcon />
								</IconButton>
							</Link>
						</Tooltip>
					</div>
				),
			},
		},
	];

	useEffect(() => {
		getdataFromApi();
	}, []);

	const getdataFromApi = () => {
		setLoading(true);
		let request = {
			purpose: endpointPurposes.get,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);
				setTableData(result.items, result.categories, result.tags);
				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	};

	const setTableData = (itemsTemp, categoriesTemp, tagsTemp) => {
		let data = [];

		itemsTemp.map((item) => {
			console.log(item.category, categoriesTemp);
			let categoryTemp = categoriesTemp.find((cat) => {
				return cat._id === item.category;
			});

			let categoryName = categoryTemp ? categoryTemp.name : "";

			console.log(categoryName);

			// let row = {
			// 	name: item.name,
			// 	country: item.locationCountry,
			// 	category: categoryName,
			// 	affiliateLink: item.affiliateLink,
			// 	id: item._id,
			// };

			let row = [
				item.name,
				item.locationCountry,
				categoryName,
				item.affiliateLink,
				item._id,
			];

			data.push(row);
		});

		console.log(data);

		setData(data);
		setCategories(categoriesTemp);
		setTags(tagsTemp);
		setProviders(itemsTemp);
	};

	const handleRowsDelete = (rowsDeleted) => {
		console.log("rows deleting: ", rowsDeleted);

		let itemsToDelete = [];
		let namesToDelete = [];
		rowsDeleted.data.map((dt) => {
			itemsToDelete.push(data[dt.index][4]);
			namesToDelete.push(data[dt.index][0]);
		});

		setProvidersToDelete(namesToDelete);

		if (itemsToDelete.length > 0) {
			setModalDeleteOpen(true);
		}

		return false;
	};

	const handleDeleteClick = () => {
		setModalDeleteOpen(true);
	};

	const handleDeleteModalCancel = () => {
		setProvidersToDelete(null);
		setModalDeleteOpen(false);
	};

	const handleDeleteConfirm = () => {
		if (providersToDelete.length > 0) {
			providersToDelete.map((prov) => {
				if (prov.logo) {
					let providerImgUri = providers
						.filter((p) => {
							return p.name == prov;
						})[0]
						.logo.split("/")[4];

					deleteItemFromS3("/providers", providerImgUri, deleteItemsApiCall);
				} else {
					deleteItemFromS3("/providers", null, deleteItemsApiCall);
				}
			});
		}
	};

	const deleteItemsApiCall = () => {
		let providerIds = [];

		providersToDelete.map((prov) => {
			let providerSingleId = providers.filter((p) => {
				return p.name == prov;
			})[0]._id;

			providerIds.push(providerSingleId);
		});

		let request = {
			purpose: endpointPurposes.delete,
			providers: providerIds,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setProvidersToDelete(null);
				setModalDeleteOpen(false);
				getdataFromApi();
			}
		});
	};

	const options = {
		filterType: "checkbox",
		print: false,
		onRowsDelete: handleRowsDelete,
	};

	return (
		<>
			<div className="admin-home-wrapper">
				<h1 className="admin-home-page-header">Providers management</h1>
				<div className="home-admin-body-wrapper">
					<Link to="/admin/providers/editcreate" path="relative">
						<Button variant="contained">Create Provider</Button>
					</Link>
					<div className="home-admin-body-list home-admin-providers-wrapper">
						{data ? (
							<MUIDataTable
								title={"Providers"}
								data={data}
								columns={columns}
								options={options}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</div>
			{providersToDelete ? (
				<Dialog
					onClose={handleDeleteModalCancel}
					aria-labelledby="categories-dialog-delete"
					open={modalDeleteOpen}
				>
					<DialogTitle
						className="admin-dialog-title"
						id="categories-dialog-delete"
					>
						Delete tag?
					</DialogTitle>
					<DialogContent>
						<div className="categories-dialog-delete-body">
							<p>Do you confirm the deleting of the providers:</p>
							<ul>
								{providersToDelete.map((p) => {
									return <li>{p}</li>;
								})}
							</ul>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleDeleteModalCancel}>Cancel</Button>
						<Button
							autoFocus
							variant="contained"
							onClick={handleDeleteConfirm}
							color="error"
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			) : (
				""
			)}
		</>
	);
};

export default AdminProviders;
