import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import DoneIcon from "@mui/icons-material/Done";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const AdminURLShorter = (props) => {
	const nameRef = useRef();
	const host = "AdminURL";
	const endpointPurposes = {
		get: "get",
		create: "create",
		delete: "delete",
	};

	const [loading, setLoading] = useRecoilState(loadingAtom);

	const [url, setUrl] = useState();

	const [urls, setUrls] = useState([]);

	const [modalOpen, setModalOpen] = useState(false);
	const [shortUrlCurrent, setShortUrlCurrent] = useState();

	const [urlToDelete, setUrlToDelete] = useState();
	const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

	const getItemsFromApi = () => {
		let request = {
			purpose: endpointPurposes.get,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);
				setUrls(result.items);
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		setLoading(true);
		getItemsFromApi();
	}, []);

	const handleInputChange = (e) => {
		setUrl(e.target.value);
	};

	const handleCreateUrl = () => {
		if (url) {
			let request = {
				purpose: endpointPurposes.create,
				url: url,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					console.log(result);
					setShortUrlCurrent(`hivee.club/a?u=${result.url}`);
					setModalOpen(true);
					getItemsFromApi();
					nameRef.current.value = null;
				}
			});
		}
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setShortUrlCurrent(null);
	};

	const handleRemoveCancel = () => {
		setUrlToDelete(null);
		setModalDeleteOpen(false);
	};

	const handleDeleteUrl = (url) => {
		if (url) {
			setUrlToDelete(url);
			setModalDeleteOpen(true);
		}
	};

	const handleRemoveConfirm = () => {
		if (urlToDelete) {
			let request = {
				purpose: endpointPurposes.delete,
				url: urlToDelete._id,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					setUrlToDelete(null);
					setModalDeleteOpen(false);
					getItemsFromApi();
				}
			});
		}
	};

	return (
		<>
			<div className="admin-home-wrapper">
				<h1 className="admin-home-page-header">URL shortener</h1>
				<div className="home-admin-body-wrapper">
					<div className="home-admin-body-wrapper-action url-create-wrapper">
						<span className="url-create-text">Create url</span>
						<div className="url-create-input-wrapper">
							<TextField
								id="name"
								label="Url"
								onChange={handleInputChange}
								name="url"
								inputRef={nameRef}
								className="url-create-input-input"
							/>
						</div>
						<div className="tags-create-actions-wrapper">
							<Stack spacing={1} direction="row">
								<Tooltip title="Create">
									<IconButton
										aria-label="edit"
										onClick={handleCreateUrl}
										color="success"
									>
										<DoneIcon />
									</IconButton>
								</Tooltip>
							</Stack>
						</div>
					</div>
					<div className="home-admin-body-list">
						{urls
							? urls.map((u) => {
									return (
										<div className="url-item-item">
											<span className="url-item-item-original">
												{u.originalUrl}
											</span>
											<span className="url-item-item-shorten">{`hivee.club/a?u=${u.shortenUrl}`}</span>

											<IconButton
												aria-label="edit"
												onClick={() => {
													navigator.clipboard.writeText(
														`hivee.club/a?u=${u.shortenUrl}`
													);
												}}
												color="success"
											>
												<ContentCopyIcon />
											</IconButton>
											<IconButton
												aria-label="delete"
												onClick={() => handleDeleteUrl(u)}
												color="warning"
												className="url-delete-icon"
											>
												<DeleteIcon />
											</IconButton>
										</div>
									);
							  })
							: ""}
					</div>
				</div>
			</div>
			<Dialog
				onClose={handleModalClose}
				aria-labelledby="categories-dialog-delete"
				open={modalOpen}
			>
				<DialogTitle className="admin-dialog-title" id="url-dialog-success">
					URL was created
				</DialogTitle>
				<DialogContent>
					<div className="categories-dialog-delete-body">
						<p>Your short URL:</p>
						<p>
							{shortUrlCurrent ? `${shortUrlCurrent}` : ""}
							<IconButton
								aria-label="edit"
								onClick={() => {
									navigator.clipboard.writeText(shortUrlCurrent);
								}}
								color="success"
							>
								<ContentCopyIcon />
							</IconButton>
						</p>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleModalClose}>OK</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={handleRemoveCancel}
				aria-labelledby="categories-dialog-delete"
				open={modalDeleteOpen}
			>
				<DialogTitle className="admin-dialog-title" id="url-dialog-success">
					Confirm remove
				</DialogTitle>
				<DialogContent>
					<div className="categories-dialog-delete-body">
						<p>Do you want to remove:</p>
						<p>{urlToDelete ? urlToDelete.shortenUrl : ""}</p>
						<p>{urlToDelete ? urlToDelete.originalUrl : ""}</p>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleRemoveCancel}>Cancel</Button>
					<Button
						variant="contained"
						color="warning"
						onClick={handleRemoveConfirm}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AdminURLShorter;
