import {
	createBrowserRouter,
	BrowserRouter as Router,
	Route,
	Routes,
} from "react-router-dom";
import Layout from "./main/Layout";
import Home from "./pages/Home";
import Login from "./login/Login";
import Header from "./shared/Header";
import Brands from "./pages/Brands";

import AdminLayout from "./admin/AdminLayout";
import AdminHome from "./admin/AdminHome";
import AdminProviders from "./admin/AdminProviders";
import AdminCategories from "./admin/AdminCategories";
import AdminTags from "./admin/AdminTags";
import AdminProviderCreateEdit from "./admin/AdminProviderCreateEdit";
import AdminURLShorter from "./admin/AdminURLShorter";
import AdminUrlRedirect from "./admin/AdminUrlRedirect";
import EmailSingle from "./main/EmailSingle";
import ProviderSingle from "./main/ProviderSingle";
import TagsAll from "./main/TagsAll";

// const router = (props) => {
// 	return (
// 		<>
// 			<Header />
// 			<Routes>
// 				<Route path="/" element={<Home />} />
// 				<Route path="/brands" element={<Brands />} />
// 			</Routes>
// 		</>
// 	);
// };

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "/brands",
				element: <Brands />,
			},
			{
				path: "/single/:emailId",
				element: <EmailSingle />,
			},
			{
				path: "/provider/:providerId",
				element: <ProviderSingle />,
			},
			{
				path: "/tags",
				element: <TagsAll />,
			},
		],
	},
	{
		path: "/admin/",
		element: <AdminLayout />,
		children: [
			{
				index: true,
				element: <AdminHome />,
			},
			{
				path: "/admin/providers",
				element: <AdminProviders />,
			},
			{
				path: "/admin/providers/editcreate/:providerId",
				element: <AdminProviderCreateEdit />,
			},
			{
				path: "/admin/providers/editcreate",
				element: <AdminProviderCreateEdit />,
			},
			{
				path: "/admin/categories",
				element: <AdminCategories />,
			},
			{
				path: "/admin/tags",
				element: <AdminTags />,
			},
			{
				path: "/admin/urls",
				element: <AdminURLShorter />,
			},
		],
	},
	{
		path: "login",
		element: <Login />,
	},
	{
		path: "a",
		element: <AdminUrlRedirect />,
	},
]);

export default router;
