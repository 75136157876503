const apiVariables = {
	s3: {
		S3_REGION: "eu-central-1",
		S3_BUCKET: "mailer-mangusta",
		AWS_ACCESS_KEY_ID: "AKIAWWUVRGQ6UOYFAKVU",
		AWS_SECRET_ACCESS_KEY: "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",
		BUCKET_URI: "https://mailer-mangusta.s3.eu-central-1.amazonaws.com",
	},
};

export default apiVariables;
