import React, { useState, useEffect } from "react";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const TagsAll = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const host = "AdminTags";
	const endpointPurposes = {
		getTagsList: "getTagsList",
	};

	const [tags, setTags] = useState();

	useEffect(() => {
		setLoading(true);
		getdataFromApi();
	}, []);

	const getdataFromApi = () => {
		setLoading(true);
		let request = {
			purpose: endpointPurposes.getTagsList,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			console.log(result);
			if (result && result.success) {
				setLoading(false);
				sortTags(result.tags);
			} else {
				console.log("request error: ", result ? result.message : "");
				setLoading(false);
			}
		});
	};

	const sortTags = (tagsArray) => {
		const groupedTags = tagsArray.reduce((acc, tag) => {
			const firstLetter = tag.name.charAt(0).toUpperCase();
			if (!acc[firstLetter]) {
				acc[firstLetter] = [];
			}
			acc[firstLetter].push(tag);
			return acc;
		}, {});

		// Create the final array containing objects for each letter with tags
		const sortedArray = Object.keys(groupedTags)
			.sort() // Sort the keys alphabetically
			.map((letter) => ({
				letter,
				tags: groupedTags[letter],
			}));

		setTags(sortedArray);
	};

	return (
		<>
			<div className="home-provider-body-wrapper">
				<div className="home-provider-content-wrapper home-tags-content-wrapper">
					<h2 className="home-tags-page-header">All tags</h2>
					{tags
						? tags.map((tag) => {
								return (
									<>
										<h4 className="home-tags-letter-header">{tag.letter}</h4>
										<div className="home-tags-letter-tags-wrapper">
											{tag.tags.map((letterTag) => {
												return (
													<span className="home-tags-letter-tags-tag">
														{letterTag.name}
													</span>
												);
											})}
										</div>
									</>
								);
						  })
						: ""}
				</div>
			</div>
		</>
	);
};

export default TagsAll;
