import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";

const HomeBanner = (props) => {
	const [] = useState();

	return (
		<>
			<div className="banner-wrapper">
				<Grid container spacing={3}>
					<Grid xs={3} xsOffset={1}>
						<div className="home-banner-categories-wrapper">
							<h2 className="home-banner-categories-header">
								Brands Categories
							</h2>
							<div className="home-banner-categories-list-wrapper">
								{props.categories
									? props.categories.map((cat) => {
											return (
												<a
													className="home-banner-categories-list-item"
													href="#"
												>
													<div className="home-banner-categories-list-item-text">
														<span className="home-banner-categories-list-item-text-icon"></span>
														<span className="home-banner-categories-list-item-text-text">
															{cat.name}
														</span>
													</div>
													<div className="home-banner-categories-list-item-counter">
														1545
													</div>
												</a>
											);
									  })
									: ""}
								{/* <a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Fashion & Accessories
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										09
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Electronics
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										1545
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Home & Decor
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										24
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Groceries
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										34
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Pets & Animals
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										65
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Autos & Vehicles
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										15
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Beauty & Cosmetics
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										76
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Jewelry & Watch
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										89
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Travel & Attractions
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										23
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Adult & Enterteinment
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										98
									</div>
								</a>
								<a className="home-banner-categories-list-item" href="#">
									<div className="home-banner-categories-list-item-text">
										<span className="home-banner-categories-list-item-text-icon"></span>
										<span className="home-banner-categories-list-item-text-text">
											Food & Drinks
										</span>
									</div>
									<div className="home-banner-categories-list-item-counter">
										98
									</div>
								</a> */}
							</div>
						</div>
					</Grid>
					<Grid xs={6}>
						<div className="home-banner-carousel-wrapper">
							<Carousel controls={false}>
								<Carousel.Item>
									<div className="home-banner-carousel-item-img-wrapper"></div>
									<Carousel.Caption>
										<h3>First slide label</h3>
										<p>
											Nulla vitae elit libero, a pharetra augue mollis interdum.
										</p>
									</Carousel.Caption>
								</Carousel.Item>
								<Carousel.Item>
									<div className="home-banner-carousel-item-img-wrapper"></div>
									<Carousel.Caption>
										<h3>Second slide label</h3>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										</p>
									</Carousel.Caption>
								</Carousel.Item>
								<Carousel.Item>
									<div className="home-banner-carousel-item-img-wrapper"></div>
									<Carousel.Caption>
										<h3>Third slide label</h3>
										<p>
											Praesent commodo cursus magna, vel scelerisque nisl
											consectetur.
										</p>
									</Carousel.Caption>
								</Carousel.Item>
							</Carousel>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default HomeBanner;
