import "./App.css";
import React from "react";

import Router from "./components/Router";
// import Router2 from "./components/Router2";
import { RouterProvider } from "react-router-dom";

import Loader from "./components/main/Loader";
import { RecoilRoot } from "recoil";

import { ErrorBoundary } from "react-error-boundary";

// import { Provider } from "react-redux";
// import store from "./store";

function App() {
	return (
		<>
			<ErrorBoundary>
				<RecoilRoot>
					<RouterProvider router={Router} />
					<Loader />
				</RecoilRoot>
			</ErrorBoundary>
		</>
	);
}

export default App;
