import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import UploadIcon from "@mui/icons-material/Upload";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

import AWS, { S3 } from "aws-sdk";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import apiVariables from "../../api/apiVariables";
import { dark } from "@mui/material/styles/createPalette";

import { processFile, deleteFile } from "../../modules/fileRepository";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const AdminCategories = (props) => {
	let fileInput = null;

	const host = "AdminCategories";
	const endpointPurposes = {
		get: "get",
		getOne: "getOne",
		create: "create",
		update: "update",
		delete: "delete",
		updateSorting: "updateSorting",
	};

	const [loading, setLoading] = useRecoilState(loadingAtom);

	const [modalOpen, setModalOpen] = useState();

	const [newCategory, setNewCategory] = useState({});

	const [imgPreview, setImgPreview] = useState();
	const [categoryImg, setCategoryImg] = useState();

	const [progress, setProgress] = useState(0);

	const [nameError, setNameError] = useState(false);
	const [imgError, setImgError] = useState(false);

	const [categories, setCategories] = useState([]);

	const [sortingChanged, setSortingChanged] = useState(false);

	const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
	const [deleteCategory, setDeleteCategory] = useState();

	const [updateMode, setUpdateMode] = useState(false);
	const [updateCategoryName, setUpdateCategoryName] = useState();

	const getCategoriesFromApi = () => {
		let request = {
			purpose: endpointPurposes.get,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);
				setCategories(result.items);

				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		setLoading(true);
		getCategoriesFromApi();
	}, []);

	const handleCreateModalOpen = () => {
		setModalOpen(true);
	};

	const handleCreateModalClose = () => {
		setModalOpen(false);
	};

	const handleDialogCancel = () => {
		setNewCategory(null);
		setImgPreview(null);
		setCategoryImg(null);
		setImgError(false);
		setNameError(false);
		setModalOpen(false);
	};

	const fileClickHandler = () => {
		fileInput.click();
	};

	const fileUploadHandler = (event) => {
		let userFile = event.target.files[0];
		event.target.value = null;

		const objectUrl = URL.createObjectURL(userFile);
		setImgPreview(objectUrl);
		setCategoryImg(userFile);
	};

	const handleInputChange = (event) => {
		setNewCategory({
			...newCategory,
			[event.target.name]: event.target.value,
		});
	};

	const handleDialogSave = async () => {
		setLoading(true);

		let isValid = validateForm();

		if (isValid) {
			// let newImgUrl = await saveImgToS3();
			console.log("sending img to processing");
			let fileObject = {
				name: newCategory.name,
				img: categoryImg,
			};
			processFile("saveCategory", fileObject, saveCategoryHandler);
		} else {
			setLoading(false);
		}
	};

	const validateForm = () => {
		let valid = true;

		if (!newCategory.name) {
			valid = false;
			setNameError(true);
		} else {
			setNameError(false);
		}

		if (!categoryImg) {
			valid = false;
			setImgError(true);
		} else {
			setImgError(false);
		}

		return valid;
	};

	const saveCategoryHandler = (categoryImgUri) => {
		console.log(categoryImgUri);

		let categoryObject = {
			name: newCategory.name,
			img: categoryImgUri,
		};

		let request = {
			purpose: endpointPurposes.create,
			category: categoryObject,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			console.log("response: ", result);
			if (result.success) {
				setNewCategory({});
				setImgPreview(null);
				setModalOpen(false);
				setLoading(false);
				getCategoriesFromApi();
				setCategoryImg(null);
			}
		});
	};

	const handleDND = (results) => {
		const { source, destination } = results;

		if (!destination) return;
		if (source.index === destination.index) return;

		const reordered = [...categories];

		const [removedItem] = reordered.splice(source.index, 1);
		reordered.splice(destination.index, 0, removedItem);

		setCategories(reordered);
		setSortingChanged(true);
	};

	const handleChangeOrderCancel = () => {
		setSortingChanged(false);
		getCategoriesFromApi();
	};

	const handleChangeOrder = () => {
		setLoading(true);
		let newCategories = categories;
		newCategories.map((item, index) => {
			newCategories[index].sorting = index + 1;
		});

		let request = {
			categories: newCategories,
			purpose: endpointPurposes.updateSorting,
		};

		console.log("request: ", request);

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result && result.success) {
				setSortingChanged(false);
				getCategoriesFromApi();

				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	};

	const handleDeleteCategory = async (id) => {
		let categoryToDelete = categories.filter((obj) => {
			return obj.id === id;
		});

		console.log("delete", id, categoryToDelete[0]);

		setDeleteCategory(categoryToDelete[0]);
		setModalDeleteOpen(true);
	};

	const handleDeleteModalClose = () => {
		setModalDeleteOpen(false);
	};

	const handleDialogDeleteCancel = () => {
		setModalDeleteOpen(false);
		setDeleteCategory(null);
	};

	const handleDialogDeleteConfirm = async () => {
		if (deleteCategory.id) {
			setLoading(true);

			deleteFile("deleteCategory", deleteCategory.img, deleteCategoryApiCall);
		}
	};

	const deleteCategoryApiCall = () => {
		let request = {
			purpose: endpointPurposes.delete,
			id: deleteCategory.id,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);
				setDeleteCategory(null);
				setModalDeleteOpen(false);
				getCategoriesFromApi();

				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	};

	const handleEditClick = (id) => {
		let updateCategory = categories.filter((obj) => {
			return obj.id === id;
		});

		console.log("Category to update: ", updateCategory);

		setUpdateMode(true);
		setNewCategory(updateCategory[0]);
		setUpdateCategoryName(updateCategory[0].name);
		setModalOpen(true);
	};

	const handleEditCancel = () => {
		setNewCategory(null);
		setUpdateMode(false);
		setModalOpen(false);
	};

	const handleEditConfirm = (id) => {
		let updateCategory = categories.filter((obj) => {
			return obj.id === id;
		});

		if (categoryImg) {
			console.log("category img exist: ", categoryImg);
			deleteFile(
				"deleteCategory",
				newCategory.img,
				updateCategoryDeleteCallback
			);
		} else {
			console.log("category img not exist: ");
			editApiCall();
		}

		// if (categoryImg) {
		// 	console.log("have a new image");
		// 	AWS.config.update({
		// 		accessKeyId: apiVariables.s3.AWS_ACCESS_KEY_ID,
		// 		secretAccessKey: apiVariables.s3.AWS_SECRET_ACCESS_KEY,
		// 	});

		// 	const myBucket = new AWS.S3({
		// 		params: { Bucket: apiVariables.s3.S3_BUCKET },
		// 		region: apiVariables.s3.S3_REGION,
		// 	});

		// 	// const name = newCategory.name + "." + categoryImg.name.split(".")[1];
		// 	const name = newCategory.img.split("/")[4];
		// 	console.log("img to delete: ", name);

		// 	const params = {
		// 		Bucket: apiVariables.s3.S3_BUCKET + "/categories",
		// 		Key: name,
		// 	};

		// 	myBucket.deleteObject(params).send((err) => {
		// 		if (err) {
		// 			console.log(err);
		// 			return false;
		// 		} else {
		// 			const newName =
		// 				newCategory.name + "." + categoryImg.name.split(".")[1];

		// 			const paramsSave = {
		// 				ACL: "public-read",
		// 				Body: categoryImg,
		// 				Bucket: apiVariables.s3.S3_BUCKET + "/categories",
		// 				Key: newName,
		// 			};

		// 			myBucket
		// 				.putObject(paramsSave)
		// 				.on("httpUploadProgress", (evt) => {
		// 					setProgress(Math.round((evt.loaded / evt.total) * 100));
		// 					console.log(Math.round((evt.loaded / evt.total) * 100));
		// 				})
		// 				.send((err) => {
		// 					if (err) {
		// 						console.log(err);
		// 						return false;
		// 					} else {
		// 						let categoryImgUri =
		// 							apiVariables.s3.BUCKET_URI + "/categories/" + newName;

		// 						let categoryToUpdate = {
		// 							id: newCategory.id,
		// 							name: newCategory.name,
		// 							img: categoryImgUri,
		// 							sorting: newCategory.sorting,
		// 						};

		// 						let request = {
		// 							purpose: endpointPurposes.update,
		// 							body: categoryToUpdate,
		// 						};

		// 						ApiRequest.apiRequest(host, request).then((result) => {
		// 							if (result.success) {
		// 								setNewCategory(null);
		// 								setImgPreview(null);
		// 								setCategoryImg(null);
		// 								setModalOpen(false);

		// 								getCategoriesFromApi();
		// 								setUpdateMode(false);

		// 								setLoading(false);
		// 							} else {
		// 								setModalOpen(false);
		// 								setUpdateMode(false);
		// 								setLoading(false);
		// 								setNewCategory(null);
		// 								setImgPreview(null);
		// 								setCategoryImg(null);
		// 							}
		// 						});
		// 					}
		// 				});
		// 		}
		// 	});
		// } else {
		// 	console.log("update without img: ", newCategory);
		// 	let categoryToUpdate = {
		// 		id: newCategory.id,
		// 		name: newCategory.name,
		// 		img: newCategory.img,
		// 		sorting: newCategory.sorting,
		// 	};

		// 	let request = {
		// 		purpose: endpointPurposes.update,
		// 		body: categoryToUpdate,
		// 	};

		// 	ApiRequest.apiRequest(host, request).then((result) => {
		// 		if (result.success) {
		// 			setNewCategory(null);
		// 			setImgPreview(null);
		// 			setCategoryImg(null);
		// 			setModalOpen(false);
		// 			setUpdateMode(false);

		// 			getCategoriesFromApi();

		// 			setLoading(false);
		// 		} else {
		// 			setModalOpen(false);
		// 			setNewCategory(null);
		// 			setImgPreview(null);
		// 			setCategoryImg(null);
		// 			setUpdateMode(false);
		// 			setLoading(false);
		// 		}
		// 	});
		// }
	};

	const updateCategoryDeleteCallback = () => {
		console.log("update callback after delete");
		let fileObject = {
			name: newCategory.name,
			img: categoryImg,
		};
		processFile("saveCategory", fileObject, editApiCall);
	};

	const editApiCall = (newCategoryImg) => {
		let categoryToUpdate = {
			id: newCategory.id,
			name: newCategory.name,
			img: newCategoryImg ? newCategoryImg : newCategory.img,
			sorting: newCategory.sorting,
		};

		let request = {
			purpose: endpointPurposes.update,
			body: categoryToUpdate,
		};
		console.log("update check: ", request);

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				setNewCategory(null);
				setImgPreview(null);
				setCategoryImg(null);
				setModalOpen(false);
				setUpdateMode(false);

				getCategoriesFromApi();

				setLoading(false);
			} else {
				setModalOpen(false);
				setNewCategory(null);
				setImgPreview(null);
				setCategoryImg(null);
				setUpdateMode(false);
				setLoading(false);
			}
		});
	};

	return (
		<>
			<div className="admin-home-wrapper">
				<h1 className="admin-home-page-header">Categories management</h1>
				<div className="home-admin-body-wrapper">
					<Button variant="contained" onClick={handleCreateModalOpen}>
						Create Category
					</Button>
					<div className="home-admin-body-change-handle-buttons">
						{sortingChanged ? (
							<>
								<Button
									variant="outlined"
									onClick={handleChangeOrder}
									className="home-admin-body-change-handle-buttons-btn"
								>
									Save the sorting
								</Button>
								<Button
									variant="text"
									onClick={handleChangeOrderCancel}
									className="home-admin-body-change-handle-buttons-btn"
								>
									Cancel the sorting change
								</Button>
							</>
						) : (
							""
						)}
					</div>
					<div className="home-admin-body-list home-admin-categories-wrapper">
						<DragDropContext onDragEnd={handleDND}>
							<Droppable droppableId="ROOT" type="group">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{categories.map((item, index) => {
											return (
												<Draggable
													draggableId={item.id}
													key={item.id}
													index={index}
												>
													{(provided) => (
														<div
															className="home-admin-categories-item-wrapper"
															{...provided.dragHandleProps}
															{...provided.draggableProps}
															ref={provided.innerRef}
														>
															<div className="home-admin-categories-item-img">
																<img
																	className="home-admin-categories-item-img-img"
																	src={item.img}
																/>
															</div>
															<div className="home-admin-categories-item-name">
																<span className="home-admin-categories-item-name-text">
																	{item.name}
																</span>
															</div>
															<div className="home-admin-categories-item-actions">
																<span className="home-admin-categories-item-actions-sorting">
																	{`Sorting: ${item.sorting}`}
																</span>
																{sortingChanged ? (
																	<span className="home-admin-categories-item-actions-sorting-new">
																		{`New: ${index + 1}`}
																	</span>
																) : (
																	""
																)}
																<div className="home-admin-categories-item-actions-edit-btn-wrapper">
																	<Stack spacing={1} direction="row">
																		<IconButton
																			aria-label="edit"
																			onClick={() => handleEditClick(item.id)}
																		>
																			<EditIcon />
																		</IconButton>
																		<IconButton
																			aria-label="delete"
																			onClick={() =>
																				handleDeleteCategory(item.id)
																			}
																		>
																			<DeleteIcon />
																		</IconButton>
																	</Stack>
																</div>
															</div>
														</div>
													)}
												</Draggable>
											);
										})}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				</div>
			</div>
			<Dialog
				onClose={updateMode ? handleEditCancel : handleCreateModalClose}
				aria-labelledby="categories-dialog"
				open={modalOpen}
			>
				<DialogTitle className="admin-dialog-title" id="categories-dialog">
					{updateMode
						? `Edit category: ${updateCategoryName}`
						: "Create category"}

					<IconButton
						aria-label="close"
						onClick={updateMode ? handleEditCancel : handleCreateModalClose}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<div className="admin-dialog-body-wrapper admin-category-dialog">
						<Box
							component="form"
							sx={{
								"& .MuiTextField-root": { m: 1, width: "25ch" },
							}}
							noValidate
							autoComplete="off"
						>
							<div className="admin-dialog-field-wrapper">
								<TextField
									required
									id="name"
									label="Category Name"
									onChange={handleInputChange}
									name="name"
									helperText={nameError ? "Name should be filled" : ""}
									error={nameError}
									defaultValue={updateMode ? newCategory.name : ""}
								/>
							</div>
							{/* <div className="admin-dialog-field-wrapper">
								<TextField
									type="number"
									required
									id="sorting"
									label="Sorting Number"
									onChange={handleInputChange}
									name="sorting"
								/>
							</div> */}
							<h3 className="admin-dialog-section-header">Category Image</h3>
							<Button
								variant="outlined"
								startIcon={<UploadIcon />}
								onClick={fileClickHandler}
							>
								Upload image
							</Button>
							{imgError ? (
								<div className="admin-categories-new-img-error-wrapper">
									<span>Category should have an image</span>
								</div>
							) : (
								""
							)}
							<input
								type="file"
								style={{ display: "none" }}
								ref={(input) => {
									fileInput = input;
								}}
								accept="image/*"
								onChange={fileUploadHandler}
							/>
							<div className="admin-categories-new-img-preview-wrapper">
								{imgPreview ? (
									<img
										src={imgPreview}
										className="admin-categories-new-img-preview-img"
									/>
								) : updateMode ? (
									<img
										src={newCategory.img}
										className="admin-categories-new-img-preview-img"
									/>
								) : (
									""
								)}
							</div>
						</Box>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={updateMode ? handleEditCancel : handleDialogCancel}>
						Cancel
					</Button>
					<Button
						autoFocus
						variant="contained"
						onClick={updateMode ? handleEditConfirm : handleDialogSave}
					>
						{updateMode ? "Confirm" : "Save"}
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				onClose={handleDeleteModalClose}
				aria-labelledby="categories-dialog-delete"
				open={modalDeleteOpen}
			>
				<DialogTitle
					className="admin-dialog-title"
					id="categories-dialog-delete"
				>
					Delete category
				</DialogTitle>
				<DialogContent>
					<div className="categories-dialog-delete-body">
						<p>Do you confirm the deleting of the category:</p>
						<p>
							{deleteCategory
								? `${deleteCategory.name} (${deleteCategory.id})`
								: ""}
						</p>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogDeleteCancel}>Cancel</Button>
					<Button
						autoFocus
						variant="contained"
						onClick={handleDialogDeleteConfirm}
						color="error"
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AdminCategories;
