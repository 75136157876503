import React, { useState, useEffect } from "react";
import { useParams, redirect, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const EmailSingle = (props) => {
	const { emailId } = useParams();

	const [loading, setLoading] = useRecoilState(loadingAtom);

	const host = "Email";

	const endpointPurposes = {
		get: "getOne",
	};

	const [email, setEmail] = useState();
	const [provider, setProvider] = useState();
	const [tags, setTags] = useState();
	const [category, setCategory] = useState();

	useEffect(() => {
		getdataFromApi();
	}, []);

	const getdataFromApi = () => {
		setLoading(true);
		let request = {
			purpose: endpointPurposes.get,
			emailId: emailId,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);

				setEmail(result.email);
				setProvider(result.provider);
				setTags(result.tags);
				setCategory(result.category);
				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	};

	return (
		<>
			<div className="home-email-body-wrapper">
				<Grid container spacing={3}>
					<Grid xs={2} xsOffset={1}>
						{provider ? (
							<div className="home-email-details-wrapper">
								<div className="email-details-logo-wrapper">
									<img
										src={provider.logo ? provider.logo : "/img/futur.png.png"}
									/>
								</div>
								<div className="email-details-provider">
									<h2 className="email-details-provider-title">
										<Link to={`/provider/${provider._id}`} path="relative">
											{provider.name}
										</Link>
									</h2>
									<h4 className="email-details-provider-website">
										{provider.website}
									</h4>
								</div>
								<div className="email-details-subject">{email.subject}</div>
								<div className="email-details-category">{category.name}</div>
								<div className="email-details-taggs-wrapper">
									{tags.map((tag) => {
										return (
											<span className="email-details-taggs-tag">
												{tag.name}
											</span>
										);
									})}
								</div>
								<div className="email-details-actions-wrapper">
									<span className="email-details-action email-details-add-to-favourite"></span>
								</div>
							</div>
						) : (
							""
						)}
					</Grid>
					<Grid xs={8}>
						<div className="home-email-email-wrapper">
							{email ? (
								<div dangerouslySetInnerHTML={{ __html: email.htmlBody }} />
							) : (
								""
							)}
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default EmailSingle;
