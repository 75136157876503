import React, { useState } from "react";

const Footer = (props) => {
	const [] = useState();

	return (
		<>
			<div className="footer-wrapper">
				<div className="footer-copy-wrapper">
					<div className="footer-copy-copy">
						Copyright &copy;2023 Hivee Club. All rights reserved
					</div>
					<div className="footer-copy-links">
						<ul className="footer-copy-links-list">
							<li className="footer-copy-links-item">
								<a href="#">Condition of use</a>
							</li>
							<li className="footer-copy-links-item">
								<a href="#">Privacy Notice</a>
							</li>
							<li className="footer-copy-links-item">
								<a href="#">Interest-Based Adds</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
