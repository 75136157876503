import React, { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

const HomeCategories = (props) => {
	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: false,
	};

	const [promotionEmails, setPromotionEmails] = useState();

	let slider = useRef(null);

	const [] = useState();

	const slideBack = () => {
		slider.slickPrev();
	};

	const slideForward = () => {
		slider.slickNext();
	};

	useEffect(() => {
		if (props.promotionEmails) {
			console.log("incoming props: ", props.promotionEmails);
			let emails = props.promotionEmails;
			let emailsArray = [];
			for (let i = 0; i < 9; i++) {
				let double = emails.splice(0, 2);
				emailsArray.push(double);
			}

			setPromotionEmails(emailsArray);
		}
	}, [props.promotionEmails]);

	useEffect(() => {
		console.log("emails effect", promotionEmails);
	}, [promotionEmails]);

	return (
		<>
			<div className="home-categories-wrapper">
				<Grid container spacing={3}>
					<Grid xs={7} xsOffset={1}>
						<div className="home-categories-carousel-wrapper">
							<div className="home-categories-carousel-header-wrapper">
								<h2 className="home-categories-carousel-header">
									Latest Deals
								</h2>
								<div className="home-categories-carousel-header-actions">
									<IconButton
										className="email-carousel-arrow"
										aria-label="previous"
										onClick={slideBack}
										name="next"
									>
										<ArrowBackIosIcon />
									</IconButton>
									<IconButton
										className="email-carousel-arrow"
										aria-label="previous"
										onClick={slideForward}
										name="back"
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</div>
							</div>
							{promotionEmails ? (
								<Slider ref={(c) => (slider = c)} {...settings}>
									{promotionEmails.map((column) => {
										return (
											<div className="home-categories-carousel-column-wrapper">
												{column.map((mail) => {
													return (
														<div className="home-categories-carousel-item-wrapper">
															<div className="home-categories-carousel-item-header">
																<Link to={`/provider/${mail.providerData._id}`}>
																	<span className="home-categories-carousel-item-provider">
																		{mail.providerData.name}
																	</span>
																</Link>
																<span className="home-categories-carousel-item-date">
																	{moment(mail.date).format("MMM Do YY")}
																</span>
															</div>
															<div className="home-categories-carousel-item-thmbn">
																<Link
																	to={`/single/${mail._id}`}
																	path="relative"
																>
																	<img src={mail.preview} />
																</Link>
															</div>
															<div className="home-categories-carousel-item-subject">
																<Link
																	to={`/single/${mail._id}`}
																	path="relative"
																>
																	{mail.subject}
																</Link>
															</div>
														</div>
													);
												})}
											</div>
										);
									})}
								</Slider>
							) : (
								""
							)}
						</div>
					</Grid>
					<Grid xs={2}>
						<div className="home-tags-wrapper">
							<div className="home-tags-header">
								<h2 className="home-tags-header-text">
									Tags
									<Link to="/tags">
										<span className="home-tags-header-view-all">View all</span>
									</Link>
								</h2>
							</div>
							<div className="home-tags-body">
								{props.tags
									? props.tags.map((tag) => {
											return (
												<a href="#">
													<span className="home-tags-tag">{tag.name}</span>
												</a>
											);
									  })
									: ""}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default HomeCategories;
