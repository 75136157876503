import React, { useState } from "react";
import Header from "../shared/Header";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
	const [] = useState();

	return (
		<>
			<Header />
			<Outlet />
		</>
	);
};

export default Layout;
