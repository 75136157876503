import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";
import apiVariables from "../../api/apiVariables";
import { IdentityStore } from "aws-sdk";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const AdminTags = (props) => {
	const nameRef = useRef();

	const host = "AdminTags";
	const endpointPurposes = {
		get: "get",
		getOne: "getOne",
		create: "create",
		update: "update",
		delete: "delete",
		updateCategories: "updateCategories",
	};

	const [personName, setPersonName] = useState([]);

	const [loading, setLoading] = useRecoilState(loadingAtom);

	const [tags, setTags] = useState();
	const [categories, setCategories] = useState([]);

	const [newTagName, setNewTagName] = useState();

	const [nameError, setNameError] = useState(false);

	const [tagToEdit, setTagToEdit] = useState();
	const [newName, setNewName] = useState("");

	const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
	const [itemToDelete, setItemToDelete] = useState();

	const getTagsFromApi = () => {
		let request = {
			purpose: endpointPurposes.get,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);

				result.items.map((item) => {
					let categoriesToDisplay = [];
					item.categories.map((cat) => {
						let categorySelected = result.categories.filter((c) => {
							return c._id == cat;
						});

						let categoryName;

						if (categorySelected[0]) {
							categoryName = categorySelected[0].name;
						}

						categoriesToDisplay.push(categoryName);
					});

					item.categoriesToDisplay = categoriesToDisplay;
				});

				setTags(result.items);
				setCategories(result.categories);

				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	};

	useEffect(() => {
		setLoading(true);
		getTagsFromApi();
	}, []);

	const handleInputChange = (event) => {
		setNewTagName(event.target.value);
	};

	const handleCreateTagClick = () => {
		if (newTagName) {
			setLoading(true);
			let tagObject = {
				name: newTagName,
			};

			let request = {
				purpose: endpointPurposes.create,
				tag: tagObject,
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				console.log("response: ", result);
				if (result) {
					setNewTagName(null);
					nameRef.current.value = null;
					getTagsFromApi();
				}
			});
		} else {
			return;
		}
	};

	const handleCancelTagClick = () => {
		setNewTagName(null);
		nameRef.current.value = null;
	};

	const handleDeleteTag = (id) => {
		console.log(id);
	};

	const handleSelectChange = (event) => {
		console.log("handle change: ", event.target.value, event.target.name);

		let tagsTemp = tags;

		tagsTemp.map((tag) => {
			if (tag.id === event.target.name) {
				// tag.categories.push(event.target.value);
			}
		});
	};

	const handleCategoriesChange = (event) => {
		console.log("update: ", event.target.name, event.target.value);
	};

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		console.log(event.target);

		let tagsTemp = tags;

		tagsTemp.map((tag) => {
			if (tag.id == event.target.name) {
				let categoriesArray = [];
				value.map((v) => {
					let category = categories.filter((cat) => {
						return cat.name == v;
					})[0];
					categoriesArray.push({ id: category._id, name: category.name });
				});
				console.log(categoriesArray);
				tag.categoriesToDisplay =
					typeof value === "string" ? value.split(",") : value;
				// tag.categories = categoriesArray;
			}
		});

		setTags(tagsTemp);

		setPersonName(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};

	const handleCategoriesSelectBlur = (event) => {
		console.log("blur: ", event, event.target.name);

		if (event.target && event.target.name) {
			let tag = tags.filter((t) => {
				return t.id == event.target.name;
			});

			let tagId = event.target.name;

			let categoriesTemp = [];

			let tagSelected = tags.filter((t) => {
				return t.id == tagId;
			})[0];

			tagSelected.categoriesToDisplay.map((cat) => {
				let categoryNameToPush = categories.filter((category) => {
					return category.name == cat;
				})[0]._id;

				categoriesTemp.push(categoryNameToPush);
			});

			let request = {
				purpose: endpointPurposes.updateCategories,
				tag: {
					id: tagId,
					categories: categoriesTemp,
				},
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					getTagsFromApi();
				}
			});
		}
	};

	const handleEditClick = (id) => {
		if (id) {
			setTagToEdit(id);
		}
	};

	const hadnleTagNameChange = (event) => {
		setNewTagName(event.target.value);
	};

	const handleEditSave = () => {
		if (newTagName && tagToEdit) {
			let request = {
				purpose: endpointPurposes.update,
				tag: {
					id: tagToEdit,
					name: newTagName,
				},
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					setTagToEdit(null);
					setNewTagName(null);
					getTagsFromApi();
				}
			});
		}
	};

	const handleEditCancel = () => {
		setTagToEdit(null);
		setNewTagName(null);
	};

	const handleDeleteClick = (id) => {
		if (id) {
			let tag = tags.filter((t) => {
				return t.id == id;
			})[0];

			setItemToDelete(tag);
			setModalDeleteOpen(true);
		}
	};

	const handleDeleteModalCancel = () => {
		setItemToDelete(null);
		setModalDeleteOpen(false);
	};

	const handleDeleteConfirm = () => {
		if (itemToDelete) {
			let request = {
				purpose: endpointPurposes.delete,
				tag: {
					id: itemToDelete.id,
				},
			};

			ApiRequest.apiRequest(host, request).then((result) => {
				if (result.success) {
					setItemToDelete(null);
					setModalDeleteOpen(false);
					getTagsFromApi();
				}
			});
		}
	};

	return (
		<>
			<div className="admin-home-wrapper">
				<h1 className="admin-home-page-header">Tags management</h1>
				<div className="home-admin-body-wrapper">
					<div className="home-admin-body-wrapper-action tags-create-wrapper">
						<span className="tags-create-text">Create tag</span>
						<div className="tags-create-input-wrapper">
							<TextField
								id="name"
								label="Tag Name"
								inputRef={nameRef}
								onChange={handleInputChange}
								name="name"
								helperText={nameError ? "Name should be filled" : ""}
								error={nameError}
								className="tags-create-input-input"
							/>
						</div>
						<div className="tags-create-actions-wrapper">
							<Stack spacing={1} direction="row">
								<Tooltip title="Create">
									<IconButton
										aria-label="edit"
										onClick={handleCreateTagClick}
										color="success"
									>
										<DoneIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Clear">
									<IconButton aria-label="clear" onClick={handleCancelTagClick}>
										<CloseIcon />
									</IconButton>
								</Tooltip>
							</Stack>
						</div>
					</div>
					<div className="tags-tags-wrapper home-admin-body-list">
						{tags
							? tags.map((item) => {
									return (
										<div className="tags-tags-item">
											{tagToEdit == item.id ? (
												<TextField
													id="nameEdit"
													className="tags-edit-name-input"
													label="Name"
													variant="standard"
													defaultValue={item.name}
													autofocus
													onChange={hadnleTagNameChange}
												/>
											) : (
												<p className="tags-tags-item-text">{item.name}</p>
											)}
											<div className="tags-tags-item-actions-wrapper">
												{tagToEdit == item.id ? (
													<>
														<Tooltip title="Save">
															<IconButton
																aria-label="save"
																onClick={handleEditSave}
																color="success"
																disabled={newTagName ? false : true}
															>
																<DoneIcon />
															</IconButton>
														</Tooltip>
														<Tooltip title="Cancel">
															<IconButton
																aria-label="cancel"
																onClick={handleEditCancel}
															>
																<CloseIcon />
															</IconButton>
														</Tooltip>
													</>
												) : (
													<>
														<IconButton
															aria-label="edit"
															onClick={() => handleEditClick(item.id)}
														>
															<EditIcon />
														</IconButton>
														<IconButton
															aria-label="delete"
															onClick={() => handleDeleteClick(item.id)}
														>
															<DeleteIcon />
														</IconButton>
													</>
												)}
											</div>
											<div className="tags-tags-item-categories-wrapper">
												<FormControl
													className="tags-categories-input-wrapper"
													variant="standard"
												>
													<InputLabel id="categories-label">
														Linked categories
													</InputLabel>
													<Select
														labelId="categories-label"
														id={`categories-${item.id}`}
														data-tag={item.id}
														multiple
														value={item.categoriesToDisplay}
														name={item.id}
														onChange={handleChange}
														onBlur={handleCategoriesSelectBlur}
														input={
															<OutlinedInput
																id="select-multiple-categories"
																label="Linked categories"
															/>
														}
														renderValue={(selected) => (
															<Box
																sx={{
																	display: "flex",
																	flexWrap: "wrap",
																	gap: 0.5,
																}}
															>
																{selected.map((value) => (
																	<Chip
																		key={
																			categories.filter((cat) => {
																				return cat.name == value;
																			})[0].name
																		}
																		label={
																			categories.filter((cat) => {
																				return cat.name == value;
																			})[0].name
																		}
																		// key={value}
																		// value={value}
																	/>
																))}
															</Box>
														)}
														MenuProps={MenuProps}
													>
														{categories.map((cat) => (
															<MenuItem key={cat.id} value={cat.name}>
																{cat.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</div>
										</div>
									);
							  })
							: ""}
					</div>
				</div>
			</div>
			<Dialog
				onClose={handleDeleteModalCancel}
				aria-labelledby="categories-dialog-delete"
				open={modalDeleteOpen}
			>
				<DialogTitle
					className="admin-dialog-title"
					id="categories-dialog-delete"
				>
					Delete tag?
				</DialogTitle>
				<DialogContent>
					<div className="categories-dialog-delete-body">
						<p>Do you confirm the deleting of the tag:</p>
						<p>
							{itemToDelete ? `${itemToDelete.name} (${itemToDelete.id})` : ""}
						</p>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteModalCancel}>Cancel</Button>
					<Button
						autoFocus
						variant="contained"
						onClick={handleDeleteConfirm}
						color="error"
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AdminTags;
