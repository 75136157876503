import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const AdminUrlRedirect = (props) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useRecoilState(loadingAtom);

	const host = "AdminURL";
	const endpointPurposes = {
		redirect: "redirect",
	};

	const [] = useState();

	useEffect(() => {
		setLoading(true);
		let url = searchParams.get("u");
		if (url) {
			redirectToUrl(url);
		}
	}, []);

	const redirectToUrl = (url) => {
		let request = {
			purpose: endpointPurposes.redirect,
			url: url,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				window.location.replace(result.redirect.originalUrl);
				setLoading(false);
			} else {
				window.location.replace("https://hivee.club/");
				setLoading(false);
			}
		});
	};

	return <></>;
};

export default AdminUrlRedirect;
