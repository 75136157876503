import React, { useState, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { Link } from "react-router-dom";

const HomeCompaniesListing = (props) => {
	const filter = props.filter;
	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 7,
		slidesToScroll: 1,
		arrows: false,
	};

	const headers = {
		national: "National Retailers",
		fashion: "Fashion & Accessories",
		electronics: "Electronics",
		beauty: "Beauty & Cosmetics",
	};

	let slider = useRef(null);

	const [] = useState();

	const slideBack = () => {
		slider.slickPrev();
	};

	const slideForward = () => {
		slider.slickNext();
	};

	return (
		<>
			<Grid container spacing={0}>
				<Grid xs={10} xsOffset={1}>
					<div className="home-listings-wrapper">
						<div className="home-listing-item-wrapper">
							<div className="home-listing-carousel-header-wrapper">
								<h2 className="home-listing-carousel-header">{`${headers[filter]} Brands`}</h2>
								<div className="home-listing-carousel-header-actions">
									<IconButton
										className="email-carousel-arrow"
										aria-label="previous"
										onClick={slideBack}
										name="next"
									>
										<ArrowBackIosIcon />
									</IconButton>
									<IconButton
										className="email-carousel-arrow"
										aria-label="previous"
										onClick={slideForward}
										name="back"
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</div>
							</div>
							{props.companies ? (
								<Slider ref={(c) => (slider = c)} {...settings}>
									{props.companies.map((company) => {
										return (
											<div className="home-carousel-brand-item-box">
												<div className="home-carousel-brand-item-wrapper">
													<div className="home-carousel-brand-item-header">
														<div className="home-carousel-brand-item-header-logo-wrapper">
															<Link
																to={`/provider/${company._id}`}
																path="relative"
															>
																<img
																	src={
																		company.logo
																			? company.logo
																			: "/img/futur.png.png"
																	}
																	alt=""
																/>
															</Link>
														</div>
														<span className="home-carousel-brand-item-header-counter">
															{company.emailsCount + " emails"}
														</span>
													</div>
													<div className="home-carousel-brand-item-body">
														<div className="home-carousel-brand-item-body-name">
															<Link
																to={`/provider/${company._id}`}
																path="relative"
															>
																{company.name}
															</Link>
														</div>
														<div className="home-carousel-brand-item-body-location">
															<FmdGoodOutlinedIcon fontSize="small" />
															{company.locationCountry}
														</div>
													</div>
												</div>
											</div>
										);
									})}
								</Slider>
							) : (
								""
							)}
						</div>
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export default HomeCompaniesListing;
