import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import logo from "../../logo.svg.png";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import { Link } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";

import FormControl from "@mui/material/FormControl";

const Header = (props) => {
	const [] = useState();

	return (
		<>
			<AppBar position="static" className="navigation-bar-wrapper">
				<div className="navigation-box-wrapper">
					<div className="navigation-left-wrapper">
						<div className="logo-wrapper">
							<Link to="/">
								<img src={logo} alt="Logo" />
							</Link>
						</div>
						<div className="navigation-search-wrapper">
							<Paper
								// component="form"
								sx={{
									p: "2px 4px",
									display: "inline-flex",
									alignItems: "center",
									width: 300,
								}}
							>
								<InputBase
									sx={{ ml: 1, flex: 1 }}
									placeholder="Search Hivee"
									inputProps={{ "aria-label": "search hivee" }}
								/>
								<IconButton
									type="button"
									sx={{ p: "10px" }}
									aria-label="search"
								>
									<SearchIcon />
								</IconButton>
							</Paper>
						</div>
						<ul className="navigation-menu-wrapper">
							<li className="navigation-menu-item">
								<a href="#">Brands</a>
							</li>
							<li className="navigation-menu-item">
								<a href="#">Categories</a>
							</li>
							<li className="navigation-menu-item">
								<a href="/admin">Admin</a>
							</li>
						</ul>
					</div>
					<div className="navigation-actions-wrapper">
						<div className="navigation-action-item-wrapper">
							<a href="#" className="navigation-item-link">
								<span className="navigation-item-img navigation-icon-location"></span>
								<span className="navigation-item-name">Germany</span>
							</a>
						</div>
						<div className="navigation-action-item-wrapper">
							<a href="#" className="navigation-item-link">
								<span className="navigation-item-img navigation-icon-favourite"></span>
								<span className="navigation-item-name">Saved</span>
							</a>
						</div>
						<div className="navigation-action-item-wrapper">
							<a href="#" className="navigation-item-link">
								<span className="navigation-item-img navigation-icon-profile"></span>
								<span className="navigation-item-name">Account</span>
							</a>
						</div>
						<div className="navigation-action-item-wrapper">
							<a href="#" className="navigation-item-link">
								<span className="navigation-item-name">DE</span>
							</a>
						</div>
					</div>
				</div>
			</AppBar>
		</>
	);
};

export default Header;
