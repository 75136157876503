import AWS, { S3 } from "aws-sdk";
import apiVariables from "../api/apiVariables";

export function saveToS3(folder, name, newName, file, callback) {}

export function updateFileS3(folder, file, name, newName, callback) {
	AWS.config.update({
		accessKeyId: apiVariables.s3.AWS_ACCESS_KEY_ID,
		secretAccessKey: apiVariables.s3.AWS_SECRET_ACCESS_KEY,
	});

	const myBucket = new AWS.S3({
		params: { Bucket: apiVariables.s3.S3_BUCKET },
		region: apiVariables.s3.S3_REGION,
	});

	const params = {
		Bucket: apiVariables.s3.S3_BUCKET + folder,
		Key: name.split("/")[4],
	};

	myBucket.deleteObject(params).send((err) => {
		if (err) {
			console.log(err);
			return false;
		} else {
			const paramsSave = {
				ACL: "public-read",
				Body: file,
				Bucket: apiVariables.s3.S3_BUCKET + folder,
				Key: newName,
			};

			myBucket
				.putObject(paramsSave)
				.on("httpUploadProgress", (evt) => {
					console.log(Math.round((evt.loaded / evt.total) * 100));
				})
				.send((err) => {
					if (err) {
						console.log(err);
						return false;
					} else {
						let newImgUri = apiVariables.s3.BUCKET_URI + folder + "/" + newName;
						callback(newImgUri);
					}
				});
		}
	});
}

export function deleteItemFromS3(folder, name, callback) {
	if (name) {
		AWS.config.update({
			accessKeyId: apiVariables.s3.AWS_ACCESS_KEY_ID,
			secretAccessKey: apiVariables.s3.AWS_SECRET_ACCESS_KEY,
		});

		const myBucket = new AWS.S3({
			params: { Bucket: apiVariables.s3.S3_BUCKET },
			region: apiVariables.s3.S3_REGION,
		});

		const params = {
			Bucket: apiVariables.s3.S3_BUCKET + folder,
			Key: name,
		};

		myBucket.deleteObject(params).send((err) => {
			if (err) {
				console.log(err);
				return false;
			} else {
				callback();
			}
		});
	} else {
		callback();
	}
}
