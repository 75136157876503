import React, { useState, useEffect, useRef } from "react";
import { useParams, redirect, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import * as ApiRequest from "../../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";
import OneLineSlider from "../shared/OneLineSlider";

const ProviderSingle = (props) => {
	const { providerId } = useParams();
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const host = "Provider";

	const endpointPurposes = {
		get: "getOne",
	};

	const [provider, setProvider] = useState();
	const [tags, setTags] = useState();
	const [category, setCategory] = useState();
	const [latestEmails, setLatestEmails] = useState();
	const [salesOffers, setSalesOffers] = useState();

	useEffect(() => {
		getdataFromApi();
	}, []);

	const getdataFromApi = () => {
		setLoading(true);
		let request = {
			purpose: endpointPurposes.get,
			provider: providerId,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);

				setProvider(result.provider);
				setTags(result.tags);
				setCategory(result.category);
				setLatestEmails(result.latestEmails);
				setSalesOffers(result.salesOffers);

				setLoading(false);
			} else {
				setLoading(false);
			}
		});
	};

	return (
		<>
			<div className="home-provider-body-wrapper">
				<div className="home-provider-content-wrapper">
					{provider ? (
						<>
							<div className="home-provider-info-wrapper">
								<div className="home-provider-info-cover-wrapper">
									<img
										src={provider.cover ? provider.cover : "/img/cover.png"}
									/>
								</div>
								<div className="home-provider-info-bio-wrapper">
									<div className="home-provider-info-bio-title-wrapper home-provider-info-bio-block">
										<h2 className="home-provider-info-bio-title-title">
											{provider.name}
										</h2>
										<h4 className="home-provider-info-bio-title-country">
											{provider.locationCountry}
										</h4>
										<h3 className="home-provider-info-bio-title-website">
											{provider.website}
										</h3>
									</div>
									<div className="home-provider-info-bio-logo-wrapper home-provider-info-bio-block">
										<div className="home-provider-info-bio-logo-logo">
											<img
												src={
													provider.logo ? provider.logo : "/img/futur.png.png"
												}
											/>
										</div>
										<div className="home-provider-info-bio-logo-follow-wrapper">
											<Button
												className="home-provider-info-bio-logo-follow-btn"
												variant="outlined"
											>
												Follow
											</Button>
										</div>
									</div>
									<div className="home-provider-info-bio-details-wrapper home-provider-info-bio-block">
										<div className="home-provider-info-bio-details-category">
											{category.name}
										</div>
										<div className="home-provider-info-bio-details-tags">
											{tags.map((tag) => {
												return (
													<span className="provider-details-taggs-tag">
														{tag.name}
													</span>
												);
											})}
										</div>
									</div>
								</div>
							</div>
							<div className="home-provider-info-description">
								<p className="home-provider-info-description-text">
									{provider.description}
								</p>
							</div>
							<div className="home-listings-wrapper">
								{salesOffers ? (
									<OneLineSlider
										emails={salesOffers}
										title="Latest sales offers"
									/>
								) : (
									""
								)}
							</div>
							<div className="home-listings-wrapper">
								{latestEmails ? (
									<OneLineSlider emails={latestEmails} title="Latest emails" />
								) : (
									""
								)}
							</div>
						</>
					) : (
						""
					)}
				</div>
			</div>
		</>
	);
};

export default ProviderSingle;
