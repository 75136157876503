import axios from "axios";
import apiConfig from "../api/apiConfig";
import * as ApiRequest from "../api/apiRequest";

export function processFile(action, file, callback) {
	const host = "Files";
	const endpointPurposes = {
		saveCategory: "saveCategory",
		updateCategory: "getupdateCategoryOne",
		saveProvider: "saveProvider",
	};

	if (endpointPurposes[action]) {
		let formData = new FormData();
		console.log(endpointPurposes[action]);
		formData.append("purpose", endpointPurposes[action]);
		formData.append("fileName", file.name);
		if (file.prevName) {
			formData.append("prevName", file.prevName);
		}
		formData.append("img", file.img);

		console.log("save new img request: ", formData);

		axios
			.post(apiConfig.Files, formData, {
				onUploadProgress: (ProgressEvent) => {
					let progress =
						Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
						"%";
				},
			})
			.then((result) => {
				if (result.data && result.data.success) {
					console.log("success: ", result);
					callback(result.data.imgLocation);
				} else {
					console.log("error: ", result);
				}
			});
	} else {
		callback(null, false, "wrong purpose");
	}
}

export function deleteFile(action, fileLocation, callback) {
	const host = "Files";
	const endpointPurposes = {
		deleteCategory: "deleteCategory",
		deleteProvider: "deleteProvider",
	};

	let request = {
		purpose: endpointPurposes[action],
		fileLocation: fileLocation,
	};

	console.log("delete img request: ", request);

	axios
		.post(apiConfig.Files, request, {
			onUploadProgress: (ProgressEvent) => {
				let progress =
					Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) + "%";
			},
		})
		.then((result) => {
			console.log(result);
			if (result.data && result.data.success) {
				console.log("success: ", result);
				callback();
			} else {
				console.log("error: ", result);
			}
		});
}
